var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-crono","visible":_vm.isAdd,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.isEdit ? 'Editar' : 'Agregar nueva')+" actividad")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(_vm.items)}}},[_c('validation-provider',{attrs:{"name":"project_id","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Proyecto","label-for":"project_id"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.proyectos,"label":"description","input-id":"project_id","reduce":function (proyectos) { return proyectos.id; },"placeholder":"Proyecto"},on:{"input":_vm.getData},model:{value:(_vm.items.projectId),callback:function ($$v) {_vm.$set(_vm.items, "projectId", $$v)},expression:"items.projectId"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sede","label-for":"sede"}},[_c('v-select',{staticClass:"select-obra",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sedes,"label":"description","input-id":"sede","reduce":function (sedes) { return sedes.id; },"placeholder":"Sede"},model:{value:(_vm.items.sedeId),callback:function ($$v) {_vm.$set(_vm.items, "sedeId", $$v)},expression:"items.sedeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"code","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código","label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","placeholder":"Código","autocomplete":"off","disabled":""},model:{value:(_vm.items.code),callback:function ($$v) {_vm.$set(_vm.items, "code", $$v)},expression:"items.code"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Actividad","label-for":"service"}},[_c('v-select',{staticClass:"select-obra",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.servicios,"input-id":"service","placeholder":"Tipo de Actividad"},model:{value:(_vm.items.type),callback:function ($$v) {_vm.$set(_vm.items, "type", $$v)},expression:"items.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dateInit","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Inicio","label-for":"dateInit"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"dateInit","config":_vm.config},model:{value:(_vm.items.dateInit),callback:function ($$v) {_vm.$set(_vm.items, "dateInit", $$v)},expression:"items.dateInit"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dateEnd","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Fin","label-for":"dateEnd"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"dateEnd","config":_vm.config},model:{value:(_vm.items.dateEnd),callback:function ($$v) {_vm.$set(_vm.items, "dateEnd", $$v)},expression:"items.dateEnd"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"route","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descripción","label-for":"route"}},[_c('b-form-input',{attrs:{"type":"text","id":"route","placeholder":"Descripción","autocomplete":"off"},model:{value:(_vm.items.route),callback:function ($$v) {_vm.$set(_vm.items, "route", $$v)},expression:"items.route"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"plate","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código de Unidad","label-for":"plate"}},[_c('b-form-input',{attrs:{"type":"text","id":"plate","placeholder":"Código de Unidad","autocomplete":"off"},model:{value:(_vm.items.plate),callback:function ($$v) {_vm.$set(_vm.items, "plate", $$v)},expression:"items.plate"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"stopQty","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cantidad de Paradas","label-for":"stopQty"}},[_c('b-form-input',{attrs:{"type":"number","id":"stopQty","placeholder":"Cantidad de Paradas","autocomplete":"off"},model:{value:(_vm.items.stopQty),callback:function ($$v) {_vm.$set(_vm.items, "stopQty", $$v)},expression:"items.stopQty"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"primaryPilotId","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Piloto 1","label-for":"primaryPilotId"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getFilteredPilots('secondaryPilotId'),"label":"fullname","input-id":"primaryPilotId","reduce":function (pilot) { return pilot.id; },"placeholder":"Piloto 1"},model:{value:(_vm.items.primaryPilotId),callback:function ($$v) {_vm.$set(_vm.items, "primaryPilotId", $$v)},expression:"items.primaryPilotId"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"secondaryPilotId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Piloto 2","label-for":"secondaryPilotId"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getFilteredPilots('primaryPilotId'),"label":"fullname","input-id":"secondaryPilotId","reduce":function (pilot) { return pilot.id; },"placeholder":"Piloto 2"},model:{value:(_vm.items.secondaryPilotId),callback:function ($$v) {_vm.$set(_vm.items, "secondaryPilotId", $$v)},expression:"items.secondaryPilotId"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"disabled":invalid,"variant":"primary","type":"submit"}},[(_vm.isDisabled)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isDisabled)?_c('span',{staticClass:"px-1"},[_vm._v("guardando...")]):_c('span',[_vm._v(_vm._s(_vm.isEdit ? 'Actualizar' : 'Agregar'))])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }